<template>
  <div class="page_content_wrap">
    <div class="expennses_loader_wrap py-5">
      <div class="container">
        <div class="expennses_loader_content_box text-center">
          <!-- <h2 class="text-dark mb-5">
	               Our algorithm is crunching the numbers to generate your personalized results.
	            </h2> -->
          <div class="expennses_loader_content_box_img">
            <img class="img-fluid" src="/images/step-animation.gif" alt="" />
            <!-- <video class="w-100" controls=false autoplay>
              <source src="/images/heyday_compressed.mp4" type="video/mp4">
            </video> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
export default {
  name: "Loader",
  components: {},
  data() {
    return {};
  },
  mounted() {
	  setTimeout(() => {
        // removing as its optional for mobile ( when encomment update > wizard.js > progressAddOnEachCall: 100 / 12,)
        // this.updateProgress({ step: 1, data: null });
		  	this.$router.push({ name: 'risk.results' });
	  }, 42000);
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
  }
};
</script>
<style scoped>
video {
  pointer-events: none;
}
</style>